import { ErrorMessage, Field } from "formik";
import { ErrorComponent } from "../../Error/ErrorComponent";
import { checkVal } from "../helperFunctions";
import MaskedInput from "react-text-mask";

export default function TextInputField(props: {
  fieldLabel: string;
  fieldSubLabel?: string;
  fieldName: string;
  required?: boolean;
  placeholder?: string;
  touched: any;
  errors: any;
  customClass?: string;
  fieldType?: string;
  mask?: any[];
  disabled?: boolean;
  extra?: any;
  onChange?: (e: any) => void;
}) {
  const {
    fieldLabel,
    fieldSubLabel,
    fieldName,
    required,
    placeholder,
    touched,
    errors,
    customClass,
    fieldType,
    mask,
    onChange,
    disabled,
    extra,
  } = props;

  const hasError = checkVal(errors, fieldName);
  const hasTouched = checkVal(touched, fieldName);
  const fieldClass = `${
    hasTouched
      ? !hasError
        ? "valid"
        : "error"
      : hasError
      ? "silent-error"
      : ""
  }`;

  if (mask) {
    return (
      <div className={`field-container ${customClass ? customClass : ""}`}>
        <label htmlFor={fieldName}>
          {fieldLabel}
          {required ? "*" : ""}
        </label>
        <br />
        {fieldSubLabel && (
          <>
            <div className="sub-label">{fieldSubLabel}</div>
          </>
        )}

        <Field
          className={fieldClass}
          required={required}
          type={fieldType ? fieldType : "text"}
          name={fieldName}
          disabled={disabled}
          placeholder={placeholder ? placeholder : ""}
          {...(onChange ? { onChange } : {})}
          render={({ field }: any) => (
            <MaskedInput
              mask={mask}
              className={fieldClass}
              {...field}
              placeholder={placeholder ? placeholder : ""}
            />
          )}
        />
        <ErrorMessage name={fieldName} render={ErrorComponent} />
      </div>
    );
  }

  return (
    <div className={`field-container ${customClass ? customClass : ""}`}>
      <div className="label-input">
        <label htmlFor={fieldName}>
          {fieldLabel}
          {required ? "*" : ""}
        </label>
        <br />
        {fieldSubLabel && (
          <>
            <div className="sub-label">{fieldSubLabel}</div>
          </>
        )}
      </div>
      <div className="field-input">
        <Field
          className={fieldClass}
          required={required}
          type={fieldType ? fieldType : "text"}
          name={fieldName}
          disabled={disabled}
          {...(onChange ? { onChange } : {})}
          placeholder={placeholder ? placeholder : ""}
        />
        <ErrorMessage name={fieldName} render={ErrorComponent} />
      </div>
    </div>
  );
}
