import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { sitePhrasing } from "../../Language/languageHandler"
import './successStyle.scss'

export default function SuccessPage() {
  const location = useLocation()
  const [transactionState, setTransactionState]: [any, any] = useState({})

  useEffect(() => {
    if (location.search && !transactionState.cart && !transactionState.error) {
      const params = new URLSearchParams(location.search)
      const transactionID = params.get('transactionID')
      const cartID = params.get('cartID')
      if (transactionID) {
        fetch(`${sitePhrasing.serverUrl}/checkout/transaction-details${transactionID.indexOf('BANKFUL') > -1 ? '-bankful' : ''}`, {
          // fetch('https://fcds-payments.herokuapp.com/checkout/transaction-details', {
          // fetch('http://local.chew.com:3004/checkout/transaction-details', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({
            cartID: cartID,
            transactionID: transactionID
          }),
        }).then(res => {
          return res.json()
        }).then(data => {
          setTransactionState({
            ...data
          })
        }).catch(err => {
          console.log('something went wrong')
          console.log(err)
          setTransactionState({ error: err.toString() })
        })
      }
    }
    return () => { }
  }, [location.search, transactionState.cart, transactionState.error])

  const calculateCartTotal = (cart: any) => {
    const {
      shippingPrice,
      discounts,
      products
    } = cart
    let total = 0

    products.forEach((x: any) => {
      total += x.productPrice
    })

    total += shippingPrice

    discounts.forEach((x: any) => {
      total -= x.discountAmount
    })

    return (total / 100).toFixed(2)
  }

  return (
    <div className="success-page">
      <h2>Transaction Summary</h2>
      {!transactionState.cart && !transactionState.error && (
        <div className="loading-transaction-details">
          <h3>Loading transaction details</h3>
          <p>Just a moment while the data is retrieved...</p>
        </div>
      )}
      {transactionState && transactionState.cart && (
        <div className="transaction-details">
          {/*<pre>{JSON.stringify(transactionState.cart, null, 2)}</pre>*/}
          {/* {<h3>Congratulations, your purchase was successful</h3>
          <p>You have been sent a confirmation email for the transaction. 
            Be sure to check your spam folder if it does not show up in your main inbox.
          </p>} */}
          <ul>
            <li>
              <b>Name:</b> {transactionState.cart.firstName} {transactionState.cart.lastName}
            </li>
            <li>
              <b>Email:</b> {transactionState.cart.email}
            </li>
            <li>
              <b>Phone:</b> {transactionState.cart.phoneNumber}
            </li>
            <li>
              <b>Business Name:</b> {transactionState.cart.businessName}
            </li>
            <li>
              <b>Total:</b> ${calculateCartTotal(transactionState.cart)}
            </li>
            <li>
              <b>Selected Products:</b><br />
              {transactionState.cart.products && transactionState.cart.products.map((p: any, idx: number) => (
                <div className="product-info" key="idx">
                  {p.productName} {/* - ${(p.productPrice / 100).toFixed(2)}*/}
                </div>
              ))}
            </li>
            {/*transactionState.cart.discounts && transactionState.cart.discounts.length > 0 && (
              <li>
                <b>Discounts:</b><br />
                {transactionState.cart.discounts.map((p: any, idx: number) => (
                  <div className="discount-info" key="idx">
                    Combination purchase - ${(p.discountAmount / 100).toFixed(2)}
                  </div>
                ))}
              </li>
                )*/}
            <li>
              <b>Shipping Method:</b> <br />
              {transactionState.cart.deliveryOption} - ${(transactionState.cart.shippingPrice / 100).toFixed(2)}
            </li>
            {transactionState.cart.deliveryOption === 'physical' && transactionState.cart.shippingAddress && (
              <li>
                <div className="shipping-information">
                  <b>Shipping Info:</b><br />
                  {transactionState.cart.shippingAddress.name} <br />
                  {transactionState.cart.shippingAddress.address.line1} <br />
                  {transactionState.cart.shippingAddress.address.line2 && (<>{transactionState.cart.shippingAddress.address.line2}<br /></>)}
                  {transactionState.cart.shippingAddress.address.city},  {transactionState.cart.shippingAddress.address.state} {transactionState.cart.shippingAddress.address.postal_code} - {transactionState.cart.shippingAddress.address.country}<br />
                </div>
              </li>
            )}
          </ul>
        </div>

      )}
      {transactionState && transactionState.error && (
        <div className="transaction-error">
          <h3>Something went wrong retrieving your transaction data</h3>
          <p>If you didn't receive a confirmation email please use the contact form and include the following information: <br />
            {location.search}
          </p>
        </div>
      )}
    </div>
  )
}