export const standardNetworkPendingCall = (message: string = '', extraState: any = {}) => (state: any, action: any) => {
  if (state.loading === 'idle') {
    return {
      ...state,
      loading: 'pending',
      currentRequestId: action.meta.requestId,
      message: message,
      ...extraState
    }
  }
}

export const standardNetworkErrorCall = (message: string = '', extraState: any = {}) => (state: any, action: any) => {
  return {
    ...state,
    loading: 'idle',
    message: message,
    error: action.payload,
    ...extraState,
  }
}
