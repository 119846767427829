import { ErrorMessage, Field } from "formik"
import { ErrorComponent } from "../../Error/ErrorComponent"
import { checkVal } from "../helperFunctions"

export default function RadioInputField(props: {
  fieldLabel: string,
  fieldName: string,
  required?: boolean,
  placeholder?: string,
  touched: any,
  errors: any,
  disabled?: boolean,
  customClass?: string,
  options: { value: string | number, label?: string }[]
  fieldSubLabel?: string,
  onChange?: (e: any) => void
}) {

  const {
    fieldLabel,
    fieldName,
    required,
    // placeholder,
    touched,
    errors,
    customClass,
    options,
    onChange,
    disabled,
    fieldSubLabel
  } = props

  const hasError = checkVal(errors, fieldName)
  const hasTouched = checkVal(touched, fieldName)
  const fieldClass = `${hasTouched ? !hasError ? '' : 'error' : hasError ? 'silent-error' : ''}`

  return (
    <div className={`field-container radio-container ${customClass ? customClass : ''}`}>
      <label htmlFor={fieldName}>{fieldLabel}{required ? '*' : ''}</label>
      <br />
      {fieldSubLabel && (<>
        <div className="sub-label">{fieldSubLabel}</div>
      </>)}

      {options.map((option, idx) => (
        <div className={'radio-entry'} key={idx}>
          <label htmlFor={fieldName}>
            <Field className={fieldClass} disabled={disabled ? true : false} {...(onChange ? { onChange } : {})} type="radio" name={fieldName} value={option.value} />
            {option.label ? option.label : option.value}
          </label>
        </div>
      ))}
      <ErrorMessage name={fieldName} render={ErrorComponent} />
    </div>
  )
}
