import { useEffect, useMemo, useRef } from "react"
import { generateBillingShippingFormInitialValues, generateBillingShippingValidationSchema } from "./BillingShippingFormHelpers"
import { Form, Formik } from "formik"
import TextInputField from "../../FormFields/TextInputField/TextInputField"
import { AddressInputFieldGroup } from "../../FormFields/AddressFieldGroup/AddressInputFieldGroup"
import RadioInputField from "../../FormFields/RadioInputField/RadioInputField"
import { yesOrNoValues } from "../PurchaseForm/PurchaseConstants"

export default function BillingShippingForm(props: {
    requiresShipping: boolean,
    requiresBilling: boolean,
    onSubmit: (data: any) => void,
    disableForm: boolean,
}) {
    const {
        requiresShipping,
        requiresBilling,
        onSubmit,
        disableForm
    } = props

    const validationSchemaFunction = useMemo(() => {
        const vFn = generateBillingShippingValidationSchema(requiresShipping, requiresBilling)
        return vFn
    }, [requiresBilling, requiresShipping])
    const formikRef: any = useRef(null);
    useEffect(() => {
        if (formikRef && formikRef.current && formikRef.current.validateForm) {
            formikRef.current.validateForm()
        }
    }, [requiresBilling, requiresShipping]);

    const scrollToError = () => {

        const silentErrors = document.querySelectorAll('.silent-error')
        if (silentErrors && silentErrors.length > 0) {
            silentErrors.forEach((x: any) => {
                if (x.focus && x.blur) {
                    x.focus()
                    x.blur()
                }
            })
        }

        const el: any = document.querySelector('.error');
        if (el) {
            (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth' });
            return
        }
        const sel: any = document.querySelector('.silent-error');
        if (sel) {
            if (sel.focus && sel.blur) {
                sel.focus();
                sel.blur();
                sel.focus();
            }
            (sel?.parentElement ?? sel)?.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <>
            <div className="purchase-form-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={generateBillingShippingFormInitialValues()}
                    validationSchema={validationSchemaFunction}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(false);
                        onSubmit(values)
                    }}
                >
                    {({
                        isSubmitting,
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setValues,
                    }) => (
                        <div className="purchase-form">
                            <Form>
                                {requiresBilling && (
                                    <>
                                        <h2 className="text-center">Billing Information</h2>
                                        <TextInputField
                                            fieldName={'billingInfo.firstName'}
                                            fieldLabel={'Billing First Name'}
                                            errors={errors}
                                            touched={touched}
                                            required={true}
                                            placeholder={'Billing First Name'}
                                        />

                                        <TextInputField
                                            fieldName={'billingInfo.lastName'}
                                            fieldLabel={'Billing Last Name'}
                                            errors={errors}
                                            touched={touched}
                                            required={true}
                                            placeholder={'Billing Last Name'}
                                        />

                                        <AddressInputFieldGroup
                                            fieldName={'billingInfo.address'}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </>
                                )}

                                {requiresShipping && (
                                    <>
                                        <h2 className="text-center">Shipping Address</h2>

                                        {requiresBilling && (
                                            <>
                                                {/* <button type="button"
                                                    className={`submit-btn btn`}
                                                    onClick={() => {
                                                        setValues({
                                                            ...values,
                                                            shippingAddress: {
                                                                name: values.billingInfo.firstName + ' ' + values.billingInfo.lastName,
                                                                address: {
                                                                    address: values.billingInfo.address.address,
                                                                    address2: values.billingInfo.address.address2,
                                                                    city: values.billingInfo.address.city,
                                                                    state: values.billingInfo.address.state,
                                                                    zip: values.billingInfo.address.zip,
                                                                },
                                                            }
                                                        }, true)
                                                    }}
                                                    disabled={errors && errors.billingInfo ? true : false}>
                                                    Same as Billing Address
                                                </button> */}

                                                <RadioInputField
                                                    fieldName={'shippingSameAsBilling'}
                                                    fieldLabel={'Is your shipping address the same as billing?'}
                                                    errors={errors}
                                                    touched={touched}
                                                    options={yesOrNoValues}
                                                    disabled={errors && errors.billingInfo ? true : false}
                                                    onChange={(e: any) => {                                                        
                                                        if (e.target.value === 'yes') {
                                                            setValues({
                                                                ...values,
                                                                shippingSameAsBilling: e.target.value,
                                                                shippingAddress: {
                                                                    name: values.billingInfo.firstName + ' ' + values.billingInfo.lastName,
                                                                    address: {
                                                                        address: values.billingInfo.address.address,
                                                                        address2: values.billingInfo.address.address2,
                                                                        city: values.billingInfo.address.city,
                                                                        state: values.billingInfo.address.state,
                                                                        zip: values.billingInfo.address.zip,
                                                                    },
                                                                }
                                                            }, true)
                                                        } else {
                                                            setFieldValue('shippingSameAsBilling', e.target.value)
                                                        }
                                                    }}
                                                />

                                            </>
                                        )}

                                        {(!requiresBilling || requiresBilling && values.shippingSameAsBilling === 'no') && (
                                            <>
                                                <TextInputField
                                                    fieldName={'shippingAddress.name'}
                                                    fieldLabel={'Shipping Name'}
                                                    errors={errors}
                                                    touched={touched}
                                                    required={true}
                                                    placeholder={'Shipping Name'}
                                                />

                                                <AddressInputFieldGroup
                                                    fieldName={'shippingAddress.address'}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </>
                                        )}
                                        {requiresBilling && values.shippingSameAsBilling === 'yes' && (
                                            <>
                                                {values.shippingAddress.name} <br />
                                                {values.shippingAddress.address.address} <br />
                                                {values.shippingAddress.address.address2 && (
                                                    <>
                                                        {values.shippingAddress.address.address2} <br />
                                                    </>
                                                )}
                                                {values.shippingAddress.address.city}, {values.shippingAddress.address.state} {values.shippingAddress.address.zip}
                                            </>
                                        )}
                                    </>
                                )}

                                <div className="form-btn-container">
                                    <button type="submit"
                                        className={`submit-btn btn ${disableForm || isSubmitting || Object.keys(errors).length > 0 ? 'disabled-btn' : ''}`}
                                        disabled={disableForm || isSubmitting || Object.keys(errors).length > 0}>
                                        Proceed to Checkout
                                    </button>

                                    {errors && Object.keys(errors).length > 0 && (
                                        <div className="field-container error-btn-container">
                                            <div className="form-error form-error-btn" onClick={scrollToError}>
                                                <b>There are errors with the form. Click here to fix</b>
                                                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
        </>
    )
}