import { sitePhrasing, titleCase } from "../../../Language/languageHandler"
import { AddressInputFieldGroup } from "../../FormFields/AddressFieldGroup/AddressInputFieldGroup"
import CheckboxInputField from "../../FormFields/CheckboxInputField/CheckboxInputField"
import RadioInputField from "../../FormFields/RadioInputField/RadioInputField"
import SelectInputField from "../../FormFields/SelectInputField/SelectInputField"
import TextInputField from "../../FormFields/TextInputField/TextInputField"
import { ContactInfoFields } from "./ContactInfoFields"
import { accomodationsPrimaryBusinessActivityDescriptionOptions, constructionPrimaryBusinessActivityDescriptionOptions, constructionPrimaryBusinessActivityDescriptionOptionsSpecifyReq, financePrimaryBusinessActivityDescriptionOptions, foodServicePrimaryBusinessActivityDescriptionOptions, healthCarePrimaryBusinessActivityDescriptionOptionsNo, healthCarePrimaryBusinessActivityDescriptionOptionsSpecifyReq, healthCarePrimaryBusinessActivityDescriptionOptionsYes, insurancePrimaryBusinessActivityDescriptionOptions, realEstatePrimaryBusinessActivityPreDescriptionOptions, realEstatePrimaryBusinessActivityRentOrLeaseDescriptionOptions, rentalLeasingPrimaryBusinessActivityDescriptionOptions, rentalLeasingPrimaryBusinessActivitySpecifyTypeOptions, retailPrimaryBusinessActivityDescriptionOptions, retailPrimaryBusinessActivitySpecifyRef, socialAssistancePrimaryBusinessActivityDescriptionOptions, transportationPrimaryBusinessActivityDescriptionOptions, transportationPrimaryBusinessActivityDescriptionOptionsPassenger, transportationPrimaryBusinessActivityPreDescriptionOptions } from "./EinTaxValueConstants"
import { einTaxIdAgreement, iAgreeText, legalStructureOptions, primaryBusinessActivityOptions, ssnOrItinOptions, termAndConditionAcceptText, whyRequestingOptions, yesOrNoValues } from "./PurchaseConstants"

export const EinTaxFields = (props: {
  errors: any;
  touched: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
}) => {
  const { values, errors, touched, setFieldValue } = props;
  return (
    <>
      {/* <TextInputField
        fieldName={'firstName'}
        fieldLabel={'First Name'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'First Name'}
      />

      <TextInputField
        fieldName={'lastName'}
        fieldLabel={'Last Name'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'Last Name'}
      />

      <TextInputField
        fieldName={'email'}
        fieldLabel={'Email'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'Email'}
      /> */}

      <ContactInfoFields
        values={values}
        touched={touched}
        errors={errors}
        setFieldValue={setFieldValue}
      />

      {/* {sitePhrasing.state !== 'Florida' && ( */}
      <SelectInputField
        fieldName={"einTaxInfo.legalStructure"}
        fieldLabel={`What type of legal structure is applying for an EIN?`}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"- Please Select -"}
        options={legalStructureOptions}
      />
      {/* )} */}

      {values.einTaxInfo.legalStructure === "Partnerships" && (
        <RadioInputField
          fieldName={"einTaxInfo.partnershipType"}
          fieldLabel={"Please select the type of partnership:"}
          errors={errors}
          required={true}
          touched={touched}
          customClass={`full-width`}
          options={[
            {
              value: "Partnership",
              label:
                "Partnership - A partnership is a relationship existing between two or more persons or groups who join together to carry on a trade or business.  Each partner contributes money, property, labor, or skill, and expects to share in the profits and losses of the business. ",
            },
            {
              value: "Joint Venture",
              label:
                "Joint Venture - A joint venture is a partnership formed between two or more business entities. These businesses share risk or expertise on a specific project or group of projects.",
            },
          ]}
        />
      )}

      <TextInputField
        fieldName={"einTaxInfo.membersInLLC"}
        fieldLabel={`Number of members with ownership stake in the ${sitePhrasing.business}, including yourself`}
        // fieldLabel={`Number of members/owners in ${sitePhrasing.business}`}
        fieldType={"number"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={`Number of members with ownership stake in the ${sitePhrasing.business}, including yourself`}
        // placeholder={`Number of members/owners in ${sitePhrasing.business}`}
      />

      <SelectInputField
        fieldName={"einTaxInfo.whyRequesting"}
        fieldLabel={`Why is the ${sitePhrasing.business} requesting an EIN?`}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"- Please Select -"}
        options={whyRequestingOptions}
      />

      <h3>
        Tell us about the responsible party of the {sitePhrasing.business}:
      </h3>

      <TextInputField
        fieldName={"einTaxInfo.responsibleParty.firstName"}
        fieldLabel={"First Name"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"First Name"}
      />

      <TextInputField
        fieldName={"einTaxInfo.responsibleParty.middleName"}
        fieldLabel={"Middle Name"}
        errors={errors}
        touched={touched}
        required={false}
        placeholder={"Middle Name"}
      />

      <TextInputField
        fieldName={"einTaxInfo.responsibleParty.lastName"}
        fieldLabel={"Last Name"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Last Name"}
      />

      <TextInputField
        fieldName={"einTaxInfo.responsibleParty.suffix"}
        fieldLabel={"Suffix (Jr, Sr, etc.)"}
        errors={errors}
        touched={touched}
        required={false}
        placeholder={"Suffix"}
      />

      <TextInputField
        mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
        fieldName={"einTaxInfo.responsibleParty.ssnItin"}
        fieldLabel={"Please provide your SSN or ITIN(# only, no dashes)"}
        fieldType={"number"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"SSN or ITIN #"}
      />

      <RadioInputField
        fieldName={"einTaxInfo.responsibleParty.ssnOrItin"}
        fieldLabel={
          "Is the number provided above a social security number SSN - OR - ITIN?"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={ssnOrItinOptions}
      />

      {values.einTaxInfo.responsibleParty.ssnOrItin === "itin" && (
        <div className="field-note">
          IMPORTANT NOTICE for those applying with an ITIN - EIN orders may take
          several weeks longer to process due to special IRS procedures. Please
          keep an eye out for an email that will be sent to you within 3
          business days of this application which requires your signature.
        </div>
      )}

      <h3>Where is the {sitePhrasing.business} physically located?</h3>
      <AddressInputFieldGroup
        fieldName={"einTaxInfo.physicalLocation"}
        errors={errors}
        touched={touched}
      />

      <RadioInputField
        fieldName={"einTaxInfo.differentAddressInfo.differentAddress"}
        fieldLabel={
          "Do you have an address different from the above where you want your mail to be sent?"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />

      {values.einTaxInfo.differentAddressInfo.differentAddress === "yes" && (
        <AddressInputFieldGroup
          fieldName={"einTaxInfo.differentAddressInfo"}
          errors={errors}
          touched={touched}
        />
      )}

      {/* <TextInputField
        mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
        fieldName={'phoneNumber'}
        fieldLabel={'Phone Number'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'Phone Number'}
      /> */}

      <h3>Tell us about the {sitePhrasing.business}.</h3>
      <TextInputField
        fieldName={"einTaxInfo.about.legalName"}
        fieldLabel={`Legal name of ${sitePhrasing.business} (must match articles of organization, if filed)`}
        errors={errors}
        touched={touched}
        onChange={(e: any) => {
          setFieldValue("einTaxInfo.about.legalName", e.target.value);
          setFieldValue("businessName", e.target.value);
        }}
        required={true}
        placeholder={`Legal Name of ${sitePhrasing.business}`}
      />

      <TextInputField
        fieldName={"einTaxInfo.about.tradeNameDBA"}
        fieldLabel={
          "Trade name/Doing business as (only if different from legal name)"
        }
        errors={errors}
        touched={touched}
        placeholder={"Trade Name/Doing business as"}
      />

      <TextInputField
        fieldName={"einTaxInfo.about.county"}
        fieldLabel={`County where ${sitePhrasing.business} is located`}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"County"}
      />

      <TextInputField
        fieldName={"einTaxInfo.about.fileDate"}
        fieldLabel={`${titleCase(
          String(sitePhrasing.business)
        )} file date with ${sitePhrasing.secOfState}`}
        fieldType={"date"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"MM/DD/YYYY"}
      />

      <SelectInputField
        fieldName={"einTaxInfo.about.closingMonthAccountingPeriod"}
        fieldLabel={`Closing month of accounting year:`}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"- Please Select -"}
        fieldSubLabel={`(most common business closing month is December)`}
        options={[
          { value: "January", label: "January" },
          { value: "February", label: "February" },
          { value: "March", label: "March" },
          { value: "April", label: "April" },
          { value: "May", label: "May" },
          { value: "June", label: "June" },
          { value: "July", label: "July" },
          { value: "August", label: "August" },
          { value: "September", label: "September" },
          { value: "October", label: "October" },
          { value: "November", label: "November" },
          { value: "December", label: "December" },
        ]}
      />

      <RadioInputField
        fieldName={"einTaxInfo.about.highwayVehicle"}
        fieldLabel={
          "Does your business own a highway motor vehicle with a taxable gross weight of 55,000 pounds or more?"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />

      <RadioInputField
        fieldName={"einTaxInfo.about.gamblingOrWagering"}
        fieldLabel={"Does your business involve gambling/wagering?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />

      <RadioInputField
        fieldName={"einTaxInfo.about.form720"}
        fieldLabel={
          "Does your business need to file Form 720 (Quarterly Federal Excise Tax Return)?"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />

      <RadioInputField
        fieldName={"einTaxInfo.about.alcoholTobaccoFirearms"}
        fieldLabel={
          "Does your business sell or manufacture alcohol, tobacco, or firearms?"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />

      <RadioInputField
        fieldName={"einTaxInfo.about.w2Info.expectW2"}
        fieldLabel={
          "Do you have, or do you expect to have, any employees who will receive Forms W-2 in the next 12 months? (Forms W-2 require additional filings with IRS)"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />

      {values.einTaxInfo.about.w2Info.expectW2 === "yes" && (
        <>
          <TextInputField
            fieldName={"einTaxInfo.about.w2Info.firstDateWages"}
            fieldLabel={
              "What is the first date wages or annuities were or will be paid?"
            }
            fieldType={"date"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"MM/DD/YYYY"}
          />

          <TextInputField
            fieldName={
              "einTaxInfo.about.w2Info.highestAgricultureEmployeeCount"
            }
            fieldLabel={
              "What is the highest number of agriculture employees expected in the next 12 months?"
            }
            fieldType={"number"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"Enter Count"}
          />

          <TextInputField
            fieldName={"einTaxInfo.about.w2Info.highestEmployeeCount"}
            fieldLabel={
              "What is the highest number of other employees expected in the next 12 months?"
            }
            fieldType={"number"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"Enter Count"}
          />

          <RadioInputField
            fieldName={"einTaxInfo.about.w2Info.taxLiableLessThan1k"}
            fieldLabel={
              "Do you expect your employment tax liability to be $1,000 or less in a full calendar year?"
            }
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
        </>
      )}

      <SelectInputField
        fieldName={"einTaxInfo.primaryBusinessActivity"}
        fieldLabel={"Primary Business Activity"}
        errors={errors}
        touched={touched}
        required={true}
        onChange={(e: any) => {
          setFieldValue("einTaxInfo.primaryBusinessActivity", e.target.value);
          setFieldValue("einTaxInfo.primaryBusinessActivityPreDescription", "");
          setFieldValue("einTaxInfo.primaryBusinessActivityDescription", "");
          setFieldValue("einTaxInfo.primaryBusinessActivitySpecifyType", "");
          setFieldValue("einTaxInfo.primaryBusinessActivitySpecify", "");
        }}
        placeholder={"- Please Select -"}
        options={primaryBusinessActivityOptions}
      />

      {values.einTaxInfo.primaryBusinessActivity === "Accommodations" && (
        <>
          <SelectInputField
            fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
            fieldLabel={
              "Please choose one of the following that best describes your primary business activity"
            }
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"- Please Select -"}
            options={accomodationsPrimaryBusinessActivityDescriptionOptions}
          />
          {values.einTaxInfo.primaryBusinessActivityDescription === "Other" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify your primary business activity"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify"}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Construction" && (
        <>
          <RadioInputField
            fieldName={"einTaxInfo.primaryBusinessActivityPreDescription"}
            fieldLabel={
              "Do you focus on a single construction trade (concrete, framing, glass, roofing, siding, electrical, plumbing, HVAC, flooring, etc.)?"
            }
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.einTaxInfo.primaryBusinessActivityPreDescription ===
            "yes" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify trade"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify trade"}
            />
          )}
          {values.einTaxInfo.primaryBusinessActivityPreDescription === "no" && (
            <SelectInputField
              fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
              fieldLabel={
                "You are not focusing on a single construction trade. Please specify your primary business activity in construction"
              }
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"- Please Select -"}
              options={constructionPrimaryBusinessActivityDescriptionOptions}
            />
          )}
          {constructionPrimaryBusinessActivityDescriptionOptionsSpecifyReq[
            values.einTaxInfo.primaryBusinessActivityDescription
          ] && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify type of property constructed"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify type of property constructed"}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Finance" && (
        <>
          <SelectInputField
            fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
            fieldLabel={
              "Please choose one of the following that best describes your primary business activity"
            }
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"- Please Select -"}
            options={financePrimaryBusinessActivityDescriptionOptions}
          />
          {values.einTaxInfo.primaryBusinessActivityDescription === "Other" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify"}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Food Service" && (
        <>
          <SelectInputField
            fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
            fieldLabel={
              "Please choose one of the following that best describes your primary business activity"
            }
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"- Please Select -"}
            options={foodServicePrimaryBusinessActivityDescriptionOptions}
          />
          {values.einTaxInfo.primaryBusinessActivityDescription === "Other" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify type of food service"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify type of food service"}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Health Care" && (
        <>
          <RadioInputField
            fieldName={"einTaxInfo.primaryBusinessActivityPreDescription"}
            fieldLabel={
              "Does your establishment include medical practitioners having the degree of M.D. (Doctor of medicine) or D.O. (Doctor of osteopathy)?"
            }
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {(values.einTaxInfo.primaryBusinessActivityPreDescription === "yes" ||
            values.einTaxInfo.primaryBusinessActivityPreDescription ===
              "no") && (
            <>
              <SelectInputField
                fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
                fieldLabel={
                  "Please choose one of the following that best describes your primary business activity"
                }
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"- Please Select -"}
                options={
                  values.einTaxInfo.primaryBusinessActivityPreDescription ===
                  "yes"
                    ? healthCarePrimaryBusinessActivityDescriptionOptionsYes
                    : healthCarePrimaryBusinessActivityDescriptionOptionsNo
                }
              />
              {healthCarePrimaryBusinessActivityDescriptionOptionsSpecifyReq[
                values.einTaxInfo.primaryBusinessActivityDescription
              ] && (
                <TextInputField
                  fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
                  fieldLabel={"Please specify"}
                  errors={errors}
                  touched={touched}
                  required={true}
                  placeholder={"Please specify"}
                />
              )}
            </>
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Insurance" && (
        <>
          <SelectInputField
            fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
            fieldLabel={
              "Please choose one of the following that best describes your primary business activity"
            }
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"- Please Select -"}
            options={insurancePrimaryBusinessActivityDescriptionOptions}
          />
          {values.einTaxInfo.primaryBusinessActivityDescription === "Other" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify"}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Manufacturing" && (
        <TextInputField
          fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
          fieldLabel={
            'Please specify the type of goods that you manufacture and the primary materials used (such as "wood furniture")'
          }
          errors={errors}
          touched={touched}
          required={true}
          placeholder={"Please specify"}
        />
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Real Estate" && (
        <>
          <SelectInputField
            fieldName={"einTaxInfo.primaryBusinessActivityPreDescription"}
            fieldLabel={"Please choose one of the following"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"- Please Select -"}
            options={realEstatePrimaryBusinessActivityPreDescriptionOptions}
          />
          {values.einTaxInfo.primaryBusinessActivityPreDescription ===
            "I rent or lease property that I own" && (
            <>
              <SelectInputField
                fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
                fieldLabel={"Please choose one of the following"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"- Please Select -"}
                options={
                  realEstatePrimaryBusinessActivityRentOrLeaseDescriptionOptions
                }
              />
              {values.einTaxInfo.primaryBusinessActivityDescription ===
                "Other" && (
                <TextInputField
                  fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
                  fieldLabel={"Please specify"}
                  errors={errors}
                  touched={touched}
                  required={true}
                  placeholder={"Please specify"}
                />
              )}
            </>
          )}
          {values.einTaxInfo.primaryBusinessActivityPreDescription ===
            "I use capital to build property" && (
            <>
              <RadioInputField
                fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
                fieldLabel={
                  "Do you focus on a single construction trade (concrete, framing, glass, roofing, siding, electrical, plumbing, HVAC, flooring, etc.)?"
                }
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
              />
              {values.einTaxInfo.primaryBusinessActivityDescription ===
                "yes" && (
                <TextInputField
                  fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
                  fieldLabel={"Please specify trade"}
                  errors={errors}
                  touched={touched}
                  required={true}
                  placeholder={"Please specify trade"}
                />
              )}
              {values.einTaxInfo.primaryBusinessActivityDescription ===
                "no" && (
                <SelectInputField
                  fieldName={"einTaxInfo.primaryBusinessActivitySpecifyType"}
                  fieldLabel={
                    "You are not focusing on a single construction trade. Please specify your primary business activity in construction"
                  }
                  errors={errors}
                  touched={touched}
                  required={true}
                  placeholder={"- Please Select -"}
                  options={
                    constructionPrimaryBusinessActivityDescriptionOptions
                  }
                />
              )}
              {constructionPrimaryBusinessActivityDescriptionOptionsSpecifyReq[
                values.einTaxInfo.primaryBusinessActivitySpecifyType
              ] && (
                <TextInputField
                  fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
                  fieldLabel={"Please specify type of property constructed"}
                  errors={errors}
                  touched={touched}
                  required={true}
                  placeholder={"Please specify type of property constructed"}
                />
              )}
            </>
          )}
          {values.einTaxInfo.primaryBusinessActivityPreDescription ===
            "Other" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify"}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Rental & Leasing" && (
        <>
          <SelectInputField
            fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
            fieldLabel={"Please choose one of the following"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"- Please Select -"}
            options={rentalLeasingPrimaryBusinessActivityDescriptionOptions}
          />
          {values.einTaxInfo.primaryBusinessActivityDescription ===
            "I rent or lease goods" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify the type of goods"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify"}
            />
          )}

          {values.einTaxInfo.primaryBusinessActivityDescription ===
            "I rent, lease, or sell real estate" && (
            <SelectInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please choose one of the following"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"- Please Select -"}
              options={rentalLeasingPrimaryBusinessActivitySpecifyTypeOptions}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Retail" && (
        <>
          <SelectInputField
            fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
            fieldLabel={"Please choose one of the following"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"- Please Select -"}
            options={retailPrimaryBusinessActivityDescriptionOptions}
          />
          {retailPrimaryBusinessActivitySpecifyRef[
            values.einTaxInfo.primaryBusinessActivityDescription
          ] && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={
                retailPrimaryBusinessActivitySpecifyRef[
                  values.einTaxInfo.primaryBusinessActivityDescription
                ]
              }
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify"}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Social Assistance" && (
        <>
          <SelectInputField
            fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
            fieldLabel={
              "Please choose one of the following that best describes your primary business activity"
            }
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"- Please Select -"}
            options={socialAssistancePrimaryBusinessActivityDescriptionOptions}
          />
          {values.einTaxInfo.primaryBusinessActivityDescription === "Other" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify type of social assistance"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify"}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Transportation" && (
        <>
          <SelectInputField
            fieldName={"einTaxInfo.primaryBusinessActivityPreDescription"}
            fieldLabel={"Do you primarily transport cargo or passengers?"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"- Please Select -"}
            options={transportationPrimaryBusinessActivityPreDescriptionOptions}
          />

          {values.einTaxInfo.primaryBusinessActivityPreDescription ===
            "Cargo" && (
            <>
              <SelectInputField
                fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
                fieldLabel={
                  "Please choose the primary mode used to transport cargo"
                }
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"- Please Select -"}
                options={
                  transportationPrimaryBusinessActivityDescriptionOptions
                }
              />
              {values.einTaxInfo.primaryBusinessActivityDescription ===
                "Other" && (
                <TextInputField
                  fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
                  fieldLabel={"Please specify"}
                  errors={errors}
                  touched={touched}
                  required={true}
                  placeholder={"Please specify"}
                />
              )}
            </>
          )}

          {values.einTaxInfo.primaryBusinessActivityPreDescription ===
            "Passengers" && (
            <>
              <SelectInputField
                fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
                fieldLabel={
                  "Please choose the primary mode used to transport passengers"
                }
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"- Please Select -"}
                options={
                  transportationPrimaryBusinessActivityDescriptionOptionsPassenger
                }
              />
              {values.einTaxInfo.primaryBusinessActivityDescription ===
                "Other" && (
                <TextInputField
                  fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
                  fieldLabel={"Please specify"}
                  errors={errors}
                  touched={touched}
                  required={true}
                  placeholder={"Please specify"}
                />
              )}
            </>
          )}

          {values.einTaxInfo.primaryBusinessActivityPreDescription ===
            "I provide a support activity for transportation" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify the support activity"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify"}
            />
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Wholesale" && (
        <>
          <RadioInputField
            fieldName={"einTaxInfo.primaryBusinessActivityDescription"}
            fieldLabel={"Do you own or take title to the goods that you sell?"}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />

          {values.einTaxInfo.primaryBusinessActivityDescription === "yes" && (
            <TextInputField
              fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
              fieldLabel={"Please specify type of goods sold"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Please specify"}
            />
          )}

          {values.einTaxInfo.primaryBusinessActivityDescription === "no" && (
            <>
              <RadioInputField
                fieldName={"einTaxInfo.primaryBusinessActivitySpecifyType"}
                fieldLabel={
                  "Do you receive a commission or fee from selling these goods?"
                }
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
              />
              {values.einTaxInfo.primaryBusinessActivitySpecifyType ===
                "no" && (
                <TextInputField
                  fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
                  fieldLabel={"Please specify type of goods sold"}
                  errors={errors}
                  touched={touched}
                  required={true}
                  placeholder={"Please specify"}
                />
              )}
            </>
          )}
        </>
      )}

      {values.einTaxInfo.primaryBusinessActivity === "Other" && (
        <TextInputField
          fieldName={"einTaxInfo.primaryBusinessActivitySpecify"}
          fieldLabel={"Please specify your primary business activity"}
          errors={errors}
          touched={touched}
          required={true}
          placeholder={"Please specify"}
        />
      )}

      <CheckboxInputField
        fieldName={"einTaxInfo.einAgreement"}
        fieldLabel={einTaxIdAgreement}
        errors={errors}
        touched={touched}
      />

      <CheckboxInputField
        fieldName={"iAgree"}
        fieldLabel={iAgreeText}
        touched={touched}
        errors={errors}
      />

      <CheckboxInputField
        fieldName={"termAndConditionAgree"}
        fieldLabel={<span dangerouslySetInnerHTML={{ __html: termAndConditionAcceptText }} />}
        touched={touched}
        errors={errors}
      />

      <TextInputField
        fieldName={"einTaxInfo.digitalSignature"}
        fieldLabel={"Client Agreement & Signature"}
        customClass={"signature-font"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Sign your name here"}
      />
    </>
  );
};
