export const basicPrimaryActivityKeys: { [v: string]: boolean } = {
  'Accommodations': true,
  'Finance': true,
  'Food Service': true,
  'Insurance': true,
  'Rental & Leasing': true,
  'Retail': true,
  'Social Assistance': true,
  'Wholesale': true,
}

export const basicPrimaryActivityPreKeys: { [v: string]: boolean } = {
  'Construction': true,
  'Health Care': true,
  'Real Estate': true,
  'Transportation': true,
}

// Accomodations
export const accomodationsPrimaryBusinessActivityDescriptionOptions = [
  { value: "Casino Hotel" },
  { value: "Hotel" },
  { value: "Motel" },
  { value: "Other" },
]

// Construction
export const constructionPrimaryBusinessActivityDescriptionOptions = [
  { value: "I construct NEW residential properties (home, condominiums, townhouses)" },
  { value: "I am involved in the remodeling of existing residential structures" },
  { value: "I construct non-residential properties (commercial, industrial)" },
  { value: "I construct other types of structures (bridges, highways, water and sewer lines, pipelines, etc.)" },
]

export const constructionPrimaryBusinessActivityDescriptionOptionsSpecifyReq: { [v: string]: boolean } = {
  "I construct non-residential properties (commercial, industrial)": true,
  "I construct other types of structures (bridges, highways, water and sewer lines, pipelines, etc.)": true,
}

// Finance
export const financePrimaryBusinessActivityDescriptionOptions = [
  { value: "Commodities broker" },
  { value: "Credit card issuing" },
  { value: "Investment advice" },
  { value: "Investment club" },
  { value: "Investment holding" },
  { value: "Mortgage broker - agent for selling mortgages" },
  { value: "Mortgage company - lending funds with real estate as collateral" },
  { value: "Portfolio management" },
  { value: "Sales financing" },
  { value: "Securities broker" },
  { value: "Trust administration" },
  { value: "Venture capital company" },
  { value: "Other" },
]

// Food Service
export const foodServicePrimaryBusinessActivityDescriptionOptions = [
  { value: "Bar" },
  { value: "Bar and restaurant" },
  { value: "Catering service" },
  { value: "Coffee shop" },
  { value: "Fast food restaurant" },
  { value: "Full service restaurant" },
  { value: "Ice cream shop" },
  { value: "Mobile food service" },
  { value: "Other" },
]

// Health Care
export const healthCarePrimaryBusinessActivityDescriptionOptionsYes = [
  { value:"Medical doctor"},
  { value:"Psychiatrist"},
  { value:"Other mental health practicioner"},
]

export const healthCarePrimaryBusinessActivityDescriptionOptionsNo = [
  { value:"Chiropractor"},
  { value:"Dentist"},
  { value:"HMO medical center"},
  { value:"Hospital"},
  { value:"Kidney dialysis center"},
  { value:"Optometrist"},
  { value:"Outpatient care center"},
  { value:"Podiatrist"},
  { value:"Psychologist"},
  { value:"Other mental health practicioner"},
  { value:"Other"},
]

export const healthCarePrimaryBusinessActivityDescriptionOptionsSpecifyReq: { [v: string]: boolean } = {
  "Other mental health practicioner": true,
  "Other": true,
}

// Insurance
export const insurancePrimaryBusinessActivityDescriptionOptions = [
  { value: "I am an insurance carrier" },
  { value: "I am an insurance agent or broker" },
  { value: "Other" },
]

// Real Estate
export const realEstatePrimaryBusinessActivityPreDescriptionOptions = [
  { value: "I rent or lease property that I own" },
  { value: "I use capital to build property" },
  { value: "I sell property for others" },
  { value: "I manage real estate for others" },
  { value: "Other" },
]

export const realEstatePrimaryBusinessActivityRentOrLeaseDescriptionOptions = [
  { value: "I rent residential real estate" },
  { value: "I rent commercial, industrial, or other real estate" },
  { value: "Other" },
]

// Rental & Leasing
export const rentalLeasingPrimaryBusinessActivityDescriptionOptions = [
  { value: "I rent, lease, or sell real estate" },
  { value: "I rent or lease goods" },
  { value: "I manage real estate for others" },
]

export const rentalLeasingPrimaryBusinessActivitySpecifyTypeOptions = [
  { value: "I am a real estate agent - I do not own the structures I rent or sell" },
  { value: "I rent residential real estate that I own" },
  { value: "I rent commercial, industrial, or other real estate that I own" },
]

// Retail
export const retailPrimaryBusinessActivityDescriptionOptions = [
  { value: "Selling goods exclusively over the internet (includes independently selling on auction sites)" },
  { value: "Sales from a storefront" },
  { value: "Direct sales" },
  { value: "Auction house" },
  { value: "Other" },
]

export const retailPrimaryBusinessActivitySpecifyRef: {[v: string]: string} = {
  "Sales from a storefront": "Please specity type of store",
  "Direct sales": "Please specify type of selling method (catalogue, mail order, door to door)",
  "Other": "Please specify",
}

// Social Assistance
export const socialAssistancePrimaryBusinessActivityDescriptionOptions = [
  { value: "Nursing home" },
  { value: "Shelter" },
  { value: "Youth services" },
  { value: "Other" },
]

// Transportation
export const transportationPrimaryBusinessActivityPreDescriptionOptions = [
  { value: "Cargo" },
  { value: "Passengers" },
  { value: "I provide a support activity for transportation" },
]

export const transportationPrimaryBusinessActivityDescriptionOptions = [
  { value: "Air" },
  { value: "Rail" },
  { value: "Trucking" },
  { value: "Water" },
  { value: "Other" },
]

export const transportationPrimaryBusinessActivityDescriptionOptionsPassenger = [
  { value: "Limousine service" },
  { value: "Shuttle bus" },
  { value: "Taxi service" },
  { value: "Other" },
]
