import { ErrorMessage, Field } from "formik";
import { ErrorComponent } from "../../Error/ErrorComponent";
import { checkVal } from "../helperFunctions";

export default function TextAreaInputField(props: {
  fieldLabel: string,
  fieldSubLabel?: string,
  fieldName: string,
  required?: boolean,
  placeholder?: string,
  touched: any,
  errors: any,
  customClass?: string,
  fieldType?: string,
  mask?: any[],
  disabled?: boolean,
  extra?: any,
  rows?: number,
  onChange?: (e: any) => void
}) {
  const {
    fieldLabel,
    fieldSubLabel,
    fieldName,
    required,
    placeholder,
    touched,
    errors,
    customClass,
    onChange,
    disabled,
    rows = 3
  } = props

  const hasError = checkVal(errors, fieldName)
  const hasTouched = checkVal(touched, fieldName)
  const fieldClass = `${hasTouched ? !hasError ? 'valid' : 'error' : hasError ? 'silent-error' : ''}`

  return (
    <div className={`field-container ${customClass ? customClass : ''}`}>
      <div className="label-input">
        <label htmlFor={fieldName}>{fieldLabel}{required ? '*' : ''}</label>
        <br />
        {fieldSubLabel && (<>
          <div className="sub-label">{fieldSubLabel}</div>
        </>)}
      </div>
      <div className="field-input">
        <Field
          className={fieldClass}
          required={required}
          as={'textarea'}
          rows={rows}
          name={fieldName}
          disabled={disabled}
          {...(onChange ? { onChange } : {})}
          placeholder={placeholder ? placeholder : ''} />
        <ErrorMessage name={fieldName} render={ErrorComponent} />
      </div>
    </div>
  )
}
