import { BlackBox } from "../Icons/BlackBoxIcon"
import { CheckMarkIcon } from "../Icons/CheckMarkIcon"

export default function CheckSelection(props: {
    selectedValues: { [key: string]: boolean },
    onChange: (key: string) => void,
    checkBoxSize?: number,
    selectionValues: { [key: string]: any },
}) {

    const {
        selectedValues,
        onChange = (v: any) => { console.log(v) },
        checkBoxSize,
        selectionValues = {}
    } = props

    return (
        <div className="check-selection-container">
            {Object.keys(selectionValues).map((k: string, idx: number) => {
                const selectionInfo: any = selectionValues[k]

                return (
                    <div key={`${k}-${idx}`} onClick={() => {
                        onChange(k)
                    }} className={`select-wrapper ${k} ${idx}`}>
                        <div className="selected-select-marker">
                            {selectedValues[k] ? (<CheckMarkIcon />) : (<BlackBox size={checkBoxSize ? checkBoxSize : 16} />)}
                        </div>
                        <div className={`select-entry ${selectedValues[k] ? 'selected' : ''}`}>
                            <div>
                                <span className="select-text" >
                                    {selectionInfo.name}
                                    <br />
                                </span>
                            </div>
                            {selectionInfo.subText && (
                                <div className="sub-text">{selectionInfo.subText}</div>
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}