import { Route, Routes } from 'react-router-dom';
import CancelPage from './Pages/Cancel/CancelPage';
import PurchasePage from './Pages/PurchasePage/PurchasePage2';
import SuccessPage from './Pages/Success/SuccessPage';
import ADPPage from './Pages/ADP/ADPPage';

function App() {
  return (
    <div className="App">
    <Routes>
      <Route path="/canceled" element={<CancelPage />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/adp" element={<ADPPage />} />
      <Route path="*" element={<PurchasePage />} />
    </Routes>
    </div>
  );
}

export default App;
