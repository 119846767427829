import { FieldArray, FieldArrayRenderProps } from "formik"
import { ErrorComponent } from "../../Error/ErrorComponent"
import { checkVal } from "../helperFunctions"
import TextInputField from "../TextInputField/TextInputField"
import RadioInputField from "../RadioInputField/RadioInputField"
import SelectFieldInput from "../SelectInputField/SelectInputField"

export interface ArrayEntryFieldType {
  fieldType: 'text' | 'number' | 'password' | 'select' | 'email' | 'radio' | 'checkbox' | 'custom',
  fieldLabel: string,
  placeholder?: string,
  fieldName: string,
  extra?: any,
  required?: boolean,
  options?: { label: string, value: string }[],
  showIf?: any
}

const textFieldTypes: any = {
  text: 1,
  number: 1,
  password: 1,
  email: 1
}

export const ArrayFieldInput = (props: {
  fieldName: string,
  values: any[],
  // setFieldValue: (f: string, v: any[]) => void,
  fields: ArrayEntryFieldType[],
  blankEntryFn: () => any,
  customClass?: string,
  // imgPreview?: {srcVal: string},
  touched: any,
  errors: any,
  removeText?: string,
  addText?: string,
  minLength?: number,
}) => {
  const {
    fieldName,
    values,
    // setFieldValue,
    fields,
    blankEntryFn,
    customClass,
    // imgPreview,
    touched,
    errors,
    removeText,
    addText,
    minLength = 0
  } = props

  const mainError: string | any = checkVal(errors, fieldName)

  return (
    <div className="field-container">
      <FieldArray
        key={fieldName}
        name={fieldName}
        render={(arrayHelpers: FieldArrayRenderProps) => (
          <div className={`field-array-input-container ${customClass ? customClass : ''}`}>
            {values.map((entry: ArrayEntryFieldType, eidx) => (
              <div key={`${fieldName}-array-entry-${eidx}`} className="field-array-entry-container">
                {eidx >= minLength && (
                  <button className="array-btn remove-btn" type="button" onClick={() => arrayHelpers.remove(eidx)}>
                    {removeText ? removeText : '- Remove'}
                  </button>
                )}
                {fields.map((fieldEntry, idx) => {

                  const {
                    showIf = {}
                  } = fieldEntry

                  let showField = true

                  if (showIf && Object.keys(showIf).length > 0) {
                    Object.keys(showIf).forEach((key: string) => {
                      if (!showIf[key](values[eidx][key])) {
                        showField = false
                      }
                    })
                  }

                  if (!showField) {
                    return null
                  }

                  if (textFieldTypes[fieldEntry.fieldType]) {
                    return (
                      <div key={`${fieldName}-array-entry-${idx}`} className="field-wrapper">
                        <TextInputField
                          fieldType={fieldEntry.fieldType}
                          fieldLabel={fieldEntry.fieldLabel}
                          required={fieldEntry.required}
                          {...(fieldEntry.extra || {})}
                          placeholder={fieldEntry.placeholder}
                          touched={touched}
                          errors={errors}
                          fieldName={`${fieldName}[${eidx}].${fieldEntry.fieldName}`} />
                      </div>
                    )
                  }

                  if (fieldEntry.fieldType === 'radio') {
                    return (
                      <div key={`${fieldName}-array-entry-${idx}`} className="field-wrapper">
                        <RadioInputField
                          fieldLabel={fieldEntry.fieldLabel}
                          required={fieldEntry.required}
                          fieldName={`${fieldName}[${eidx}].${fieldEntry.fieldName}`}
                          options={fieldEntry.options || []}
                          touched={touched}
                          {...(fieldEntry.extra || {})}
                          errors={errors}
                        />
                      </div>
                    )
                  }

                  if (fieldEntry.fieldType === 'select') {
                    return (
                      <SelectFieldInput
                        key={`${fieldName}-array-entry-${idx}`}
                        fieldLabel={fieldEntry.fieldLabel}
                        required={fieldEntry.required}
                        fieldName={`${fieldName}[${eidx}].${fieldEntry.fieldName}`}
                        placeholder={fieldEntry.placeholder ? fieldEntry.placeholder : '- Select -'}
                        options={fieldEntry.options || []}
                        touched={touched}
                        {...(fieldEntry.extra || {})}
                        errors={errors}
                      />
                    )
                  }

                  if (fieldEntry.fieldType === 'custom' && fieldEntry.extra?.component) {
                    return (
                      <div
                        key={`${fieldName}-array-entry-${idx}`}
                        className="field-wrapper custom-field-wrapper"
                      >
                        {fieldEntry.extra.component()}
                      </div>
                    )
                  }

                  return (
                    <div key={`${fieldName}-array-entry-${eidx}`} className="field-wrapper">
                      Field Type Undefined
                      <br />
                    </div>
                  )
                })}
              </div>
            ))}
            <button className="array-btn add-btn" type="button" onClick={() => arrayHelpers.push(blankEntryFn())}>
              {addText ? addText : '+ Add'}
            </button>
          </div>
        )} />
      {mainError && typeof mainError === 'string' && (
        ErrorComponent(mainError)
      )}
    </div>
  )
}
