import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import CheckboxInputField from "../../FormFields/CheckboxInputField/CheckboxInputField";
import * as yup from "yup";
import TextInputField from "../../FormFields/TextInputField/TextInputField";
import { sitePhrasing } from "../../../Language/languageHandler";
import { companyName } from "../PurchaseForm/PurchaseConstants";


export default function ADPForm(props: any) {

    const {
        // onSubmit,
        // selectedProducts,
        disableForm,
        // initialFormOptions = {},
    } = props;

    const formikRef: any = useRef(null);

    const [successfulSubmission, setSuccessfulSubmission] = useState(false);

    const scrollToError = () => {
        const silentErrors = document.querySelectorAll(".silent-error");
        if (silentErrors && silentErrors.length > 0) {
            console.log("silentErrors", silentErrors);
            silentErrors.forEach((x: any) => {
                if (x.focus && x.blur) {
                    x.focus();
                    x.blur();
                }
                const yPosition = x.getBoundingClientRect().top + window.scrollY
                console.log("yPosition", yPosition);
                window.scrollTo({ top: yPosition, behavior: "smooth" });
            });
        }
    }

    const onSubmit = async (values: any) => {
        console.log("values", values);
        const response = await fetch(`${sitePhrasing.heraclesUrl}/api/adp-submissions/site-add-submission`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                adpSubmissionInfo: values,
                mailServer: `${sitePhrasing.serverUrl}/messaging/send-email`,
            }),
        }).catch((error) => {
            console.error('Error:', error);
        });

        if (response && response.ok) {
            const data = await response.json();
            console.log("data", data)
            return data;
        }

        console.log("response", response);

        return response;
    }


    if (successfulSubmission) {
        return <ADPThanks />
    }


    return (
        <>
            <div className="purchase-form-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        created: new Date(),
                        updated: new Date(),
                        firstName: "",
                        lastName: "",
                        email: "",
                        phoneNumber: "",
                        businessName: "",
                        interestedServices: {
                            payroll: false,
                            retirement: false,
                            workersComp: false,
                            healthBenefits: false,
                            timeKeeping: false,
                            peoAndHrOutsourcing: false,
                            hR: false,
                        },
                        consentToShare: false,
                        collectedState: sitePhrasing.state,
                        leadStatus: "new",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        // console.log("values",values);
                        const response = await onSubmit(values);
                        console.log('response =====>', response);
                        setSubmitting(false);
                        // onSubmit(values);

                        if (response && response._id) {
                            setSuccessfulSubmission(true);
                        }

                    }}
                >
                    {({
                        isSubmitting,
                        // values,
                        errors,
                        touched,
                        // setFieldValue,
                        // setValues,
                    }) => (
                        <div className="general-form">
                            <Form>
                                <img src="https://business-filing-center.s3.us-east-1.amazonaws.com/adp-image-1728933104595.png" alt="" />
                                <br /><br />
                                <p>
                                    We are proud to partner with ADP® to bring our clients additional business services in combination with document preparation.
                                    Every client interested in ADP® services will deal directly with our assigned agent, Luis Castellon,
                                    who will facilitate simple communication for account setup and offer assistance as needed.
                                    As your main point of contact, Luis will be able to assess any additional needs or areas of interest for you,
                                    and conduct a free screening of your company in order to help you establish or enhance a better structure.
                                    He has cost-effective solutions and experience with businesses like yours!
                                </p>

                                <br />

                                {errors && errors.interestedServices && (
                                    <div className="silent-error" />
                                )}
                                <p>
                                    ADP® provides a large array of services to its clients.
                                    Please select from the list below those which you would like to receive further information on.
                                    (Please select at least one)*
                                </p>


                                <CheckboxInputField
                                    fieldName={"interestedServices.payroll"}
                                    fieldLabel={"Payroll"}
                                    touched={touched}
                                    errors={errors}
                                />

                                <CheckboxInputField
                                    fieldName={"interestedServices.retirement"}
                                    fieldLabel={"Retirement"}
                                    touched={touched}
                                    errors={errors}
                                />

                                <CheckboxInputField
                                    fieldName={"interestedServices.workersComp"}
                                    fieldLabel={"Workers Comp"}
                                    touched={touched}
                                    errors={errors}
                                />

                                <CheckboxInputField
                                    fieldName={"interestedServices.healthBenefits"}
                                    fieldLabel={"Health & Benefits"}
                                    touched={touched}
                                    errors={errors}
                                />

                                <CheckboxInputField
                                    fieldName={"interestedServices.timeKeeping"}
                                    fieldLabel={"Timekeeping"}
                                    touched={touched}
                                    errors={errors}
                                />

                                <CheckboxInputField
                                    fieldName={"interestedServices.peoAndHrOutsourcing"}
                                    fieldLabel={"PEO and Human Resource Outsourcing"}
                                    touched={touched}
                                    errors={errors}
                                />

                                <CheckboxInputField
                                    fieldName={"interestedServices.hR"}
                                    fieldLabel={"HR"}
                                    touched={touched}
                                    errors={errors}
                                />

                                {errors && errors.interestedServices && (
                                    <div className="field-container">
                                        <div className="form-error">{errors.interestedServices}</div>
                                    </div>
                                )}

                                <br />
                                <p>
                                    Also, please provide us with a little more information about yourself and your business.
                                </p>

                                <TextInputField
                                    fieldName={'firstName'}
                                    fieldLabel={'First Name'}
                                    errors={errors}
                                    touched={touched}
                                    required={true}
                                    placeholder={'First Name'}
                                />

                                <TextInputField
                                    fieldName={'lastName'}
                                    fieldLabel={'Last Name'}
                                    errors={errors}
                                    touched={touched}
                                    required={true}
                                    placeholder={'Last Name'}
                                />

                                <TextInputField
                                    fieldName={'businessName'}
                                    fieldLabel={'Company'}
                                    errors={errors}
                                    touched={touched}
                                    required={true}
                                    placeholder={'Company'}
                                />

                                <TextInputField
                                    fieldName={'email'}
                                    fieldLabel={'Email'}
                                    errors={errors}
                                    touched={touched}
                                    required={true}
                                    placeholder={'Email'}
                                />

                                <TextInputField
                                    mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                    fieldName={'phoneNumber'}
                                    fieldLabel={'Phone Number'}
                                    errors={errors}
                                    touched={touched}
                                    required={true}
                                    placeholder={'Phone Number'}
                                />

                                <br />

                                <CheckboxInputField
                                    fieldName={"consentToShare"}
                                    fieldLabel={`By checking this box, I consent to ${companyName} sharing the data I provide with ADP® in order to receive information about its products and services.`}
                                    touched={touched}
                                    errors={errors}
                                />

                                <div className="final-purchase-form-section">
                                    <div className="form-btn-container">
                                        <button
                                            type="submit"
                                            className={`submit-btn btn ${disableForm || isSubmitting || Object.keys(errors).length > 0 ? "disabled-btn" : ""}`}
                                            disabled={disableForm || isSubmitting || Object.keys(errors).length > 0}
                                        >
                                            Submit
                                        </button>

                                        {errors && Object.keys(errors).length > 0 && (
                                            <div className="field-container error-btn-container">
                                                <div
                                                    className="form-error form-error-btn"
                                                    onClick={scrollToError}
                                                >
                                                    <b>
                                                        There are errors with the form. Click here to fix
                                                    </b>
                                                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
        </>
    )
}

const validationSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email().required("Email is required"),
    phoneNumber: yup.string().required().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i, "Invalid phone number"),
    businessName: yup.string().required("Company is required"),
    interestedServices: yup.object().test('interestedServices', 'At least one service must be selected', (value) => {
        let selectedKeys = 0
        for (const key in value) {
            if (value[key]) {
                selectedKeys++
            }
        }
        return selectedKeys > 0
    }),
    consentToShare: yup.boolean().oneOf([true], "Consent to share is required")
})

function ADPThanks() {
    return (<>
        <div className="purchase-form-container">
            <div className="general-form">
                <div
                    style={{
                        width: '100%',
                        textAlign: "center",
                    }}
                >
                    <img
                        style={{
                            maxWidth: "300px",
                            margin: "0 auto",
                        }}
                        src="https://business-filing-center.s3.us-east-1.amazonaws.com/adp-logo-1728933089950.png"
                        alt="" />

                </div>
                <br />
                        
                <h2 style={{ textAlign: "center" }}>
                    Thank you for your interest in ADP®!
                </h2>

                <p style={{ textAlign: "center", fontSize: "1.2rem" }}>
                    A representative will be reaching out to you within the next few business days.
                </p>
                <br />
                {/* Youtube video embeded here https://www.youtube.com/watch?v=2bou-gqR7gg */}

                <div
                    style={{
                        width: '100%',
                        height: 0,
                        padding: "56.25% 0 0 0",
                        position: "relative",
                    }}
                >
                    <iframe
                        // width="560"
                        // height="315"
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                        src="https://www.youtube.com/embed/2bou-gqR7gg"
                        title="RUN Powered By ADP"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>

                    </iframe>
                </div>
            </div>
        </div>
    </>)
}