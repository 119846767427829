import { sitePhrasing } from "../../../../Language/languageHandler";
import { AddressInputFieldGroup } from "../../../FormFields/AddressFieldGroup/AddressInputFieldGroup";
import { ArrayEntryFieldType, ArrayFieldInput } from "../../../FormFields/ArrayField/ArrayFieldInput";
import CheckboxInputField from "../../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../../FormFields/RadioInputField/RadioInputField";
import SelectInputField from "../../../FormFields/SelectInputField/SelectInputField";
import TextInputField from "../../../FormFields/TextInputField/TextInputField";
import { blankRegisteredAgentFn, feiEinStatusOptions, iAgreeText, registeredAgentFields, termAndConditionAcceptText, yesOrNoValues } from "../PurchaseConstants";
import { forcedStatePrincipal } from "../PurchaseFormHelpers";

export default function IndianaAnnualReportFields(props: {
  errors: any;
  touched: any;
  values: any;
}) {
  const {
    errors,
    touched,
    values
  } = props;
  return (<>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <RadioInputField
        fieldName={'annualReport.entityStructure'}
        fieldLabel={'Please select which of the following best describes your business entity structure'}
        errors={errors}
        required={true}
        touched={touched}
        options={[
          { value: 'LLC or other', label: 'LLC or other' },
          { value: 'Professional Corporation', label: 'Professional Corporation' },
        ]}
        customClass={'block-radio'}
      />
    </div>

    {values.annualReport.entityStructure === 'Professional Corporation' && (
      <div>
        <h3>Please provide the following required shareholder information: </h3>

        {/* Display "Additional Shareholder" label if there are existing shareholders */}
        <ArrayFieldInput
          fieldName={'annualReport.shareholders'}
          values={values?.annualReport?.shareholders || []}
          blankEntryFn={generateBlankShareholder}
          fields={INDIANA_SHAREHOLDER_FIELDS}
          touched={touched}
          errors={errors}
          removeText={'- Click to remove Additional Shareholder'}
          addText={'+ Click to add Additional Shareholder'}
          minLength={1}
        />
      </div>
    )}
    <TextInputField
      mask={[/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      fieldName={'annualReport.feiEin'}
      fieldLabel={'FEI/EIN Number'}
      errors={errors}
      touched={touched}
      required={false}
      placeholder={'FEI/EIN Number'}
    />

    <RadioInputField
      fieldName={"annualReport.feiEinStatus"}
      fieldLabel={"FEI/EIN Number Status"}
      errors={errors}
      required={true}
      touched={touched}
      options={feiEinStatusOptions}
      customClass={"block-radio"}
    />
    <div className="field-note">
      If you have not yet obtained your EIN Federal Tax ID, you may do so now by{" "}<a href="/" target="_blank" className="link-text">clicking here</a>.
    </div>

    <h3>Do you need to change any of the following information?</h3>

    <RadioInputField
      fieldName={
        "annualReport.informationChange.emailAddress.emailAddressChange"
      }
      fieldLabel={"E-Mail on file?"}
      errors={errors}
      required={true}
      touched={touched}
      options={yesOrNoValues}
    />
    {values.annualReport.informationChange.emailAddress.emailAddressChange === "yes" && (
      <TextInputField
        fieldName={"annualReport.informationChange.emailAddress.newEmailAddress"}
        fieldLabel={"Enter your new COMPLETE e-mail address"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Enter your new COMPLETE e-mail address"}
      />
    )}

    <RadioInputField
      fieldName={"annualReport.informationChange.principalAddress.changed"}
      fieldLabel={"Principal Address?"}
      errors={errors}
      required={true}
      touched={touched}
      options={yesOrNoValues}
    />
    {values.annualReport.informationChange.principalAddress.changed === "yes" && (
      <AddressInputFieldGroup
        fieldName={"annualReport.informationChange.principalAddress.newAddress"}
        errors={errors}
        touched={touched}
        fieldMessages={{
          state: forcedStatePrincipal[sitePhrasing.state] ? `Your principal address must be located in ${sitePhrasing.state}.` : ``,
        }}
        disableFields={{
          state: forcedStatePrincipal[sitePhrasing.state] ? true : false,
        }}
      />
    )}

    <RadioInputField
      fieldName={"annualReport.informationChange.registeredAgent.agentChange"}
      fieldLabel={"Registered Agent?"}
      errors={errors}
      required={true}
      touched={touched}
      options={yesOrNoValues}
    />
    {values.annualReport.informationChange.registeredAgent.agentChange === "yes" && (
      <>
        <RadioInputField
          fieldName={"annualReport.informationChange.registeredAgent.agentChangeType"}
          fieldLabel={"What type of change?"}
          errors={errors}
          required={true}
          touched={touched}
          customClass={`full-width`}
          options={[
            { value: "address", label: "Change my current Registered Agent's address only", },
            { value: "nameAddress", label: "Change my Registered Agent's name and address", },
          ]}
        />

        {values.annualReport.informationChange.registeredAgent.agentChangeType === "nameAddress" && (
          <>
            <RadioInputField
              fieldName={"annualReport.informationChange.registeredAgent.agentType"}
              fieldLabel={"My Registered Agent is"}
              errors={errors}
              required={true}
              touched={touched}
              options={[
                { value: "individual", label: "Individual" },
                { value: "business", label: "Business" },
              ]}
            />
          </>
        )}

        {values.annualReport.informationChange.registeredAgent.agentType === "individual" && (
          <>
            <TextInputField
              fieldName={"annualReport.informationChange.registeredAgent.firstName"}
              fieldLabel={"First Name"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"First Name"}
            />
            <TextInputField
              fieldName={"annualReport.informationChange.registeredAgent.lastName"}
              fieldLabel={"Last Name"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Last Name"}
            />
            <TextInputField
              fieldName={"annualReport.informationChange.registeredAgent.email"}
              fieldLabel={"Email"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Email"}
            />
          </>
        )}

        {values.annualReport.informationChange.registeredAgent.agentType === "business" && (
          <>
            <TextInputField
              fieldName={"annualReport.informationChange.registeredAgent.businessName"}
              fieldLabel={"Business Name"}
              errors={errors}
              touched={touched}
              required={true}
              placeholder={"Business Name"}
            />
            <div className="field-note">
              * Business must be active and registered in the state of
              Indiana. Please type business name exactly as it appears on the
              Indiana Secretary of State to avoid errors and delays in your
              order. It is possible we may contact you if we are unable to
              find locate the business.
            </div>
          </>
        )}

        {(values.annualReport.informationChange.registeredAgent.agentChangeType === "address" ||
          values.annualReport.informationChange.registeredAgent.agentType === "individual") && (
            <AddressInputFieldGroup
              fieldName={"annualReport.informationChange.registeredAgent.newAddress"}
              errors={errors}
              touched={touched}
            />
          )}
      </>
    )}

    <RadioInputField
      fieldName={"annualReport.informationChange.authorizedPerson.authorizedPersonChange"}
      fieldLabel={"Governing Person?"}
      errors={errors}
      required={true}
      touched={touched}
      options={yesOrNoValues}
    />
    {values.annualReport.informationChange.authorizedPerson.authorizedPersonChange === "yes" && (
      <>
        <RadioInputField
          fieldName={"annualReport.informationChange.authorizedPerson.removingAgents"}
          fieldLabel={`Are you removing any agents?`}
          errors={errors}
          required={true}
          touched={touched}
          options={yesOrNoValues}
        />
        {values.annualReport.informationChange.authorizedPerson.removingAgents === "yes" && (
          <ArrayFieldInput
            fieldName={"annualReport.informationChange.authorizedPerson.removedAgents"}
            values={values.annualReport.informationChange.authorizedPerson.removedAgents}
            blankEntryFn={blankRegisteredAgentFn}
            fields={registeredAgentFields}
            touched={touched}
            errors={errors}
            removeText={"- Click to remove entry"}
            addText={"+ Click to add an entry"}
          />
        )}

        <RadioInputField
          fieldName={"annualReport.informationChange.authorizedPerson.addingAgents"}
          fieldLabel={`Are you adding any agents?`}
          errors={errors}
          required={true}
          touched={touched}
          options={yesOrNoValues}
        />
        {values.annualReport.informationChange.authorizedPerson.addingAgents === "yes" && (
          <ArrayFieldInput
            fieldName={"annualReport.informationChange.authorizedPerson.addedAgents"}
            values={values.annualReport.informationChange.authorizedPerson.addedAgents}
            blankEntryFn={blankRegisteredAgentFn}
            fields={registeredAgentFields}
            touched={touched}
            errors={errors}
            removeText={"- Click to remove entry"}
            addText={"+ Click to add an entry"}
          />
        )}
      </>
    )}

    <CheckboxInputField
      fieldName={'annualReport.annualAgree'}
      fieldLabel={`With my digital signature, (i) I represent and warrant that all of the information provided above is accurate and complete; (ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and (iii) I have read and understand that I am hereby authorizing Indiana Business Filing Center as a "Third Party Designee", to answer any questions on my behalf or the behalf of the entity listed above about the completion of this Business Entity Report, and to receive and deliver to me the Business Entity Report for me or the entity listed above.`}
      touched={touched}
      errors={errors}
    />

    <CheckboxInputField
      fieldName={"iAgree"}
      fieldLabel={iAgreeText}
      touched={touched}
      errors={errors}
    />

    <CheckboxInputField
      fieldName={"termAndConditionAgree"}
      fieldLabel={<span dangerouslySetInnerHTML={{ __html: termAndConditionAcceptText }} />}
      touched={touched}
      errors={errors}
    />

    <CheckboxInputField
      fieldName={"annualReport.tellingTruth"}
      fieldLabel={`In Witness Whereof, the undersigned hereby verifies, subject to the penalties of perjury, that the statements contained herein are true, this day, ${new Date().toLocaleDateString()}`}
      touched={touched}
      errors={errors}
    />

    <CheckboxInputField
      fieldName={"annualReport.iAmAuthorized"}
      fieldLabel={
        "The undersigned acknowledges that a person commits a Class A misdemeanor by signing a document that the person knows is false in a material respect with the intent that the document be delivered to the Secretary of State for filing."
      }
      touched={touched}
      errors={errors}
    />

    <br />

    <TextInputField
      fieldName={"annualReport.electronicSignature"}
      fieldLabel={"Name of Individual Signing - Electronic Signature"}
      errors={errors}
      touched={touched}
      required={true}
      placeholder={"Electronic Signature"}
      customClass={"signature-font"}
    />

    <SelectInputField
      fieldName={"annualReport.title"}
      fieldLabel={"Title"}
      errors={errors}
      touched={touched}
      required={true}
      placeholder={"- Please Select -"}
      options={[
        { value: "member", label: "Member" },
        { value: "manager", label: "Manager" },
        { value: "registeredAgent", label: "Registered Agent" },
        { value: "cpa", label: "CPA" },
        { value: "authorizedAgent", label: "Authorized Agent" },
        { value: "legalRepresentative", label: "Legal Representative" },
      ]}
    />
  </>
  );
}

const generateBlankShareholder = () => ({
  firstName: '',
  lastName: '',
  profession: '',
  indianaProfessionalLicenseNumber: '',
  country: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
})

const INDIANA_SHAREHOLDER_FIELDS: ArrayEntryFieldType[] = [
  {
    fieldName: 'firstName',
    fieldLabel: 'First Name',
    fieldType: 'text',
    required: true,
  },
  {
    fieldName: 'lastName',
    fieldLabel: 'Last Name',
    fieldType: 'text',
    required: true,
  },
  {
    fieldName: 'profession',
    fieldLabel: 'Profession',
    fieldType: 'text',
    required: true,
  },
  {
    fieldName: 'indianaProfessionalLicenseNumber',
    fieldLabel: 'Indiana Professional License Number',
    fieldType: 'text',
    required: true,
  },
  {
    fieldName: 'country',
    fieldLabel: 'Country',
    fieldType: 'text',
    required: true,
  },
  {
    fieldName: 'address',
    fieldLabel: 'Street Address 1',
    fieldType: 'text',
    required: true,
  },
  {
    fieldName: 'address2',
    fieldLabel: 'Street Address 2 (apt, suite, unit, building, floor, etc.)',
    fieldType: 'text',
  },
  {
    fieldName: 'city',
    fieldLabel: 'City',
    fieldType: 'text',
    required: true,
  },
  {
    fieldName: 'state',
    fieldLabel: 'State',
    fieldType: 'text',
    required: true,
  },
  {
    fieldName: 'zip',
    fieldLabel: 'Zip',
    fieldType: 'text',
    required: true,
  }
]