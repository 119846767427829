import { useRef } from "react";
import ADPForm from "../../Components/Forms/ADPForm/ADPForm";

export default function ADPPage() {

    const topRef = useRef<HTMLDivElement>(null);

    return (
        <div className="split-page-container">
            <div ref={topRef} className="split-page-info-section">
                <h1>
                    ADP®
                </h1>

            </div>

            <div className="split-page-action-section">
                <div className="split-page-action-information">
                    <div className="purchase-step">                    
                        <ADPForm />
                    </div>
                </div>
            </div>
        </div>
    )
}