import { ErrorMessage, Field } from "formik";
import { ErrorComponent } from "../../Error/ErrorComponent";
import { checkVal } from "../helperFunctions";

export default function CheckboxInputField(props: {
  fieldLabel: React.ReactNode,
  fieldName: string,
  errors: any,
  touched: any,
  customClass?: string,
  customLabel?: any,
})  {
  const {
    fieldLabel,
    fieldName,
    errors,
    touched,
    customClass,
    customLabel,
  } = props

  const hasError = checkVal(errors, fieldName)
  const hasTouched = checkVal(touched, fieldName)
  const fieldClass = `${hasTouched ? !hasError ? '' : 'error' : hasError ? 'silent-error' : ''}`

    return (
      <div className={`field-container ${customClass ? customClass : ''}`}>
        <label htmlFor={fieldName} className={"check-box"}>
          <Field className={fieldClass} type="checkbox" name={fieldName} />
          {customLabel ? customLabel() : fieldLabel}
        </label>
        <br />
        <ErrorMessage name={fieldName} render={ErrorComponent} />
      </div>
    )
}
