import { sitePhrasing } from "../../../Language/languageHandler"
import { AddressInputFieldGroup } from "../../FormFields/AddressFieldGroup/AddressInputFieldGroup"
import CheckboxInputField from "../../FormFields/CheckboxInputField/CheckboxInputField"
import RadioInputField from "../../FormFields/RadioInputField/RadioInputField"
import TextInputField from "../../FormFields/TextInputField/TextInputField"
import { iAgreeText, legalNoticeAdvertisementAgreement, termAndConditionAcceptText, yesOrNoValues } from "./PurchaseConstants"

export const LegalNoticeAdvertisementFields = (props: {
  errors: any;
  touched: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
}) => {
  const { values, errors, touched, setFieldValue } = props;
  return (
    <>
      <TextInputField
        fieldName={"einTaxInfo.about.tradeNameDBA"}
        fieldLabel={"Fictitious Name/DBA(Doing Business As)"}
        errors={errors}
        required={true}
        touched={touched}
        placeholder={"Fictitious Name/DBA(Doing Business As)"}
        onChange={(e: any) => {
          setFieldValue("einTaxInfo.about.tradeNameDBA", e.target.value);
          setFieldValue("businessName", e.target.value);
        }}
      />

      <h3>Where is the {sitePhrasing.business} physically located?</h3>
      <div className="text-center text-mb">
        <b className="red-text">
          IMPORTANT: One of the addresses provided below MUST BE IN THE STATE OF{" "}
          {sitePhrasing.stateAbbreviation}
        </b>
      </div>
      <AddressInputFieldGroup
        fieldName={"einTaxInfo.physicalLocation"}
        errors={errors}
        touched={touched}
      />

      <RadioInputField
        fieldName={"einTaxInfo.differentAddressInfo.differentAddress"}
        fieldLabel={
          "Do you have an address different from the above where you want your mail to be sent?"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />

      {values.einTaxInfo.differentAddressInfo.differentAddress === "yes" && (
        <AddressInputFieldGroup
          fieldName={"einTaxInfo.differentAddressInfo"}
          errors={errors}
          touched={touched}
        />
      )}

            <CheckboxInputField
                fieldName={'einTaxInfo.einAgreement'}
                fieldLabel={legalNoticeAdvertisementAgreement}
                errors={errors}
                touched={touched}
            />

            {sitePhrasing.state === "Florida" && (
                <>
                    <CheckboxInputField
                        fieldName={"iAgree"}
                        fieldLabel={iAgreeText}
                        touched={touched}
                        errors={errors}
                    />

                    <CheckboxInputField
                        fieldName={"termAndConditionAgree"}
                        fieldLabel={
                            <span
                                dangerouslySetInnerHTML={{ __html: termAndConditionAcceptText }}
                            />
                        }
                        touched={touched}
                        errors={errors}
                    />
                </>
            )}

      <TextInputField
        fieldName={"einTaxInfo.digitalSignature"}
        fieldLabel={"Client Agreement & Signature"}
        customClass={"signature-font"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Sign your name here"}
      />
    </>
  );
};
